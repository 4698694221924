<template>
  <div class="auth-card android text-center">
    <h2 class="auth-card__title mb-8">Спасибо, Вы прошли тест полностью!</h2>

    <div class="android" />
  </div>
</template>

<script>
export default {
  name: 'Finish',
};
</script>
