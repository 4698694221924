export const TEST_TYPES = {
  MMPI: 'MMPI',
  NUMERIC_LOGIC: 'NUMERIC_LOGIC',
  VERBAL_LOGIC: 'VERBAL_LOGIC',
  NON_VERBAL_LOGIC: 'NON_VERBAL_LOGIC',
  PROF_INTERESTS: 'PROF_INTERESTS',
};
export const TEST_TYPES_TITLES = {
  MMPI: 'МИЛЛ',
  VERBAL_LOGIC: 'Вербальная логика',
  NON_VERBAL_LOGIC: 'Невербальная логика',
  NUMERIC_LOGIC: 'Числовая логика',
  PROF_INTERESTS: 'Проф интересы',
};
