import api from '@/common/api';

const initialState = () => ({
  token: '',
  loading: false,
  errors: [],
  candidate: null,
  currentStep: '',

  descriptionTestData: null,
  testData: null,

  selectedAnswer: null,
});

export default {
  namespaced: true,
  state: initialState,
  actions: {
    setToken({ commit }, token) {
      commit('setToken', token);
    },
    async loadStep({
      state,
      commit,
    }) {
      commit('loadStepRequest');
      try {
        const response = await api.get(`/testing-session?token=${state.token}`);
        commit('loadStepSuccess', response);
      } catch (err) {
        commit('loadStepFailure', Object.entries(err.data));
      }
    },

    async nextStep({
      state,
      commit,
    }) {
      commit('loadNextStepRequest');
      try {
        const response = await api.post(`/testing-session/next?token=${state.token}`);
        commit('loadNextStepSuccess', response);
      } catch (err) {
        commit('loadNextStepFailure', Object.entries(err.data));
      }
    },

    async backStep({
      state,
      commit,
    }) {
      commit('loadNextStepRequest');
      try {
        const response = await api.post(`/testing-session/back?token=${state.token}`);
        commit('loadNextStepSuccess', response);
      } catch (err) {
        commit('loadNextStepFailure', Object.entries(err.data));
      }
    },

    async sendAnswer({
      state,
      commit,
      dispatch,
    }) {
      commit('sendAnswerRequest');
      const data = {
        test_type: state.testData.test_type,
        answer_id: state.selectedAnswer.id,
        question_id: state.testData.question.id,
      };
      try {
        const response = await api.post(`/testing-session/answer-question?token=${state.token}`, data);
        commit('sendAnswerSuccess', response);
        dispatch('nextStep');
      } catch (err) {
        commit('sendAnswerFailure', Object.entries(err.data));
        throw err;
      }
    },

    async sendPointsAnswer({
      state,
      commit,
    }, answer) {
      commit('sendPointsAnswerRequest');
      const data = {
        test_type: state.testData.test_type,
        answer_id: answer.id,
        question_id: state.testData.question.id,
        points: answer.points,
      };
      try {
        const response = await api.post(`/testing-session/answer-question?token=${state.token}`, data);
        commit('sendPointsAnswerSuccess', response);
      } catch (err) {
        commit('sendPointsAnswerFailure', Object.entries(err.data));
        throw err;
      }
    },

    async updatePersonalData({
      state,
      commit,
      dispatch,
    }, { form }) {
      commit('updatePersonalDataRequest');
      const data = {
        ...form,
        email: state.candidate.email,
      };
      try {
        const response = await api.put(`/testing-session/fill-personal-data?token=${state.token}`, data);
        commit('updatePersonalDataSuccess', response);
        dispatch('nextStep');
      } catch (err) {
        commit('updatePersonalDataFailure', Object.entries(err.data));
        throw err;
      }
    },

    setAnswer({ commit }, answer) {
      commit('setAnswer', answer);
    },
    setSelectedAnswer({ commit }, answer) {
      commit('setSelectedAnswer', answer);
    },
    clearError({ commit }) {
      commit('setError', []);
    },
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    loadStepRequest(state) {
      state.loading = true;
      state.errors = [];
      state.currentStep = '';
    },
    loadStepSuccess(state, data) {
      state.currentStep = data.current_step;

      state.testData = data;

      if (data.candidate) state.candidate = data.candidate;

      state.loading = false;
    },
    loadStepFailure(state, error) {
      state.errors = error;
      state.currentStep = '';
      state.loading = false;
    },

    loadNextStepRequest(state) {
      state.loading = true;
      state.errors = [];
      // state.currentStep = '';
    },
    loadNextStepSuccess(state, data) {
      state.currentStep = data.current_step;

      state.testData = data;

      if (data.candidate) state.candidate = data.candidate;

      state.selectedAnswer = null;

      state.loading = false;
    },
    loadNextStepFailure(state, error) {
      state.errors = error;
      // state.currentStep = '';
      state.loading = false;
    },

    updatePersonalDataRequest(state) {
      state.loading = true;
      state.errors = [];
    },
    updatePersonalDataSuccess(state) {
      // state.currentStep = currentStep ?? state.currentStep;
      state.loading = false;
    },
    updatePersonalDataFailure(state, error) {
      state.errors = Object.entries(error.data);
      // state.currentStep = '';
      state.loading = false;
    },

    sendAnswerRequest(state) {
      state.loading = true;
      state.errors = [];
    },
    sendAnswerSuccess(state) {
      state.loading = false;
    },
    sendAnswerFailure(state, error) {
      state.errors = Object.entries(error.data);
      state.loading = false;
    },
    sendPointsAnswerRequest(state) {
      state.loading = true;
      state.errors = [];
    },
    sendPointsAnswerSuccess(state, response) {
      const findIndAnswer = state.testData.answer_options.findIndex((a) => a.id === response.answer);
      const answer = state.testData.answer_options[findIndAnswer];

      state.testData.answer_options.splice(findIndAnswer, 1, {
        ...answer,
        points: response.points,
      });

      state.testData.question.remaining_points = response.remaining_points;

      state.loading = false;
    },
    sendPointsAnswerFailure(state, error) {
      state.errors = Object.entries(error.data);
      state.loading = false;
    },
    setSelectedAnswer(state, answer) {
      state.selectedAnswer = answer;
    },
    setAnswer(state, answer) {
      const findIndAnswer = state.testData.answer_options.findIndex((a) => a.id === answer.id);
      state.testData.answer_options.splice(findIndAnswer, 1, answer);
    },
  },
  getters: {
    loading: (state) => state.loading,
    errors: (state) => state.errors,
    candidate: (state) => state.candidate,
    currentStep: (state) => state.currentStep,
    testData: (state) => state.testData,
    descriptionTestData: (state) => state.descriptionTestData,
    selectedAnswer: (state) => state.selectedAnswer,
  },
};
