import api from '@/common/api';

const initialState = () => ({
  loading: {
    load: false,
    create: false,
    loadResults: false,
  },
  errors: {
    load: [],
    create: [],
    loadResults: [],
  },
  filter: {
    page: 1,
  },
  count: 0,
  managers: [],
  selected: [],
  editedManager: null,
  dialogCreateShow: false,
});

export default {
  namespaced: true,
  state: initialState,
  actions: {
    async loadManagers({ commit }) {
      commit('loadManagersRequest');
      try {
        const response = await api.get('/users/managers/');
        commit('setManagers', response);
        commit('setCount', response.length);
        commit('loadManagersSuccess');
      } catch (err) {
        commit('loadManagersFailure', Object.entries(err.data));
        throw err;
      }
    },
    async loadManagersResults({ commit, getters }) {
      commit('loadManagersResultsRequest');
      const data = {
        candidates: getters.selected,
      };
      try {
        const response = await api.post('/users/candidates/get-test-result/', data);
        commit('loadManagersResultsSuccess');
        return response;
      } catch (err) {
        commit('loadManagersResultsFailure', Object.entries(err.data));
        throw err;
      }
    },
    async createManager({ getters, commit, dispatch }, data) {
      commit('createManagerRequest');
      try {
        await api.post('/users/managers/', data);
        commit('createManagerSuccess');
        if (getters.filter.page === 1) {
          dispatch('loadManagers');
        }
      } catch (err) {
        commit('createManagerFailure', Object.entries(err.data));
        throw err;
      }
    },
    async setFilterPage({ commit, dispatch }, page) {
      commit('setFilterPage', page);
      await dispatch('loadManagers');
    },
    clearError({ commit }) {
      commit('clearError');
    },
    setSelected({ commit }, id) {
      commit('setSelected', id);
    },
    allSelected({ commit }) {
      commit('allSelected');
    },
    clearSelected({ commit }) {
      commit('clearSelected');
    },
    setDialogCreateShow({ commit }, bool) {
      if (!bool) commit('setEditedManager', null);
      commit('setDialogCreateShow', bool);
    },
  },
  mutations: {
    loadManagersRequest(state) {
      state.loading.load = true;
      state.errors.load = [];
    },
    loadManagersSuccess(state) {
      state.loading.load = false;
    },
    loadManagersFailure(state, error) {
      state.errors.load = error;
      state.loading.load = false;
    },

    loadManagersResultsRequest(state) {
      state.loading.loadResults = true;
      state.errors.loadResults = [];
    },
    loadManagersResultsSuccess(state) {
      state.loading.loadResults = false;
    },
    loadManagersResultsFailure(state, error) {
      state.errors.loadResults = error;
      state.loading.loadResults = false;
    },

    createManagerRequest(state) {
      state.loading.create = true;
      state.errors.create = [];
    },
    createManagerSuccess(state) {
      state.loading.create = false;
    },
    createManagerFailure(state, error) {
      state.errors.create = error;
      state.loading.create = false;
    },

    setCount(state, count) {
      state.count = count;
    },
    clearSelected(state) {
      state.selected = [];
    },
    clearError(state) {
      state.errors = initialState().errors;
    },
    setManagers(state, managers) {
      state.managers = managers;
    },

    setFilterPage(state, page) {
      state.filter.page = parseInt(page, 10);
    },

    setSelected(state, id) {
      if (id || id === 0) {
        const itemFound = state.selected.some((item) => item === id);
        if (itemFound) {
          state.selected = state.selected.filter((item) => item !== id);
        } else {
          state.selected.push(id);
        }
      } else {
        state.selected = [];
      }
    },
    allSelected(state) {
      state.managers.forEach((c) => {
        if (!state.selected.some((id) => c.id === id)) {
          state.selected.push(c.id);
        }
      });
    },
    setEditedManager(state, manager) {
      state.editedManager = manager;
    },
    setDialogCreateShow(state, bool) {
      state.dialogCreateShow = bool;
    },
  },
  getters: {
    loading: (state) => state.loading,
    errors: (state) => state.errors,
    count: (state) => state.count,
    filter: (state) => state.filter,
    managers: (state) => state.managers,
    selected: (state) => state.selected,
    editedManager: (state) => state.editedManager,
    dialogCreateShow: (state) => state.dialogCreateShow,
  },
};
