// /^[a-zA-Zа-яёА-ЯЁ]+$/u

export const phoneValidation = (value) => value.match(/\d+/g)?.join([]).length === 11;

export const firstnameValidation = (value) => {
  if (!value) return true;
  return /^[а-яёА-ЯЁ\s]+$/u.test(value);
};
export const lastnameValidation = (value) => {
  if (!value) return true;
  return /^[а-яёА-ЯЁ\s]+$/u.test(value);
};

export const startSpacesValidation = (value) => !/^\s/.test(value);
