<template>
  <div class="bg-main d-flex flex-column h-100">
    <div :class="showAssistantClass"></div>
    <HeaderAuth />
    <div class="d-flex justify-center mt-5">
      <Error v-if="errors.length"/>
      <component v-else v-bind:is="activeComponent" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { STEPS } from '@/common/constants/test';
import HeaderAuth from '@/components/headers/HeaderAuth';
import Policy from '@/components/tests/Policy';
import PersonalInformation from '@/components/tests/PersonalInformation';
import TestComponent from '@/components/tests/Test';
import Start from '@/components/tests/Start';
import Finish from '@/components/tests/Finish';
import Error from '@/components/tests/Error';

export default {
  name: 'Test',
  // metaInfo: {
  //   title: 'Начать тестирование',
  // },
  components: {
    HeaderAuth,
    Policy,
    PersonalInformation,
    TestComponent,
    Start,
    Finish,
    Error,
  },
  computed: {
    ...mapGetters({
      currentStep: 'testing/testing/currentStep',
      testData: 'testing/testing/testData',
      errors: 'testing/testing/errors',
    }),
    activeComponent() {
      switch (this.currentStep) {
        case STEPS.DATA_PROCESSING_POLICY: return 'Policy';
        case STEPS.INPUT_PERSONAL_DATA: return 'PersonalInformation';
        case STEPS.TEST_DESCRIPTION: return 'Start';
        case STEPS.TEST: return 'TestComponent';
        case STEPS.FINISH: return 'Finish';
        default: return '';
      }
    },
    showAssistantClass() {
      if (this.currentStep === STEPS.TEST) {
        const questionNum = this.testData.question_number;
        const questionCount = this.testData.questions_count;
        // уведомление хорошее начало (при прохождении 5% теста)
        if (Math.ceil((questionCount * 5) / 100) === questionNum) {
          return 'assistant-good-start';
        }

        // уведомление отлично получается (каждые 50 вопросов)
        if (questionNum % 50 === 0) {
          return 'assistant-great';
        }

        // уведомление почти у цели (при прохождении 95% теста)
        if (Math.floor((questionCount * 95) / 100) === questionNum) {
          return 'assistant-almost-on-target';
        }

        // уведомление, осталось менее 50 вопросов
        if (questionCount - questionNum === 49) {
          return 'assistant-fifty-questions-left';
        }
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      setToken: 'testing/testing/setToken',
      loadStep: 'testing/testing/loadStep',
      nextStep: 'testing/testing/nextStep',
    }),
  },
  async created() {
    this.setToken(this.$route.params.token);
    await this.loadStep();
  },
};
</script>
