<template>
  <div v-if="user">
    <div v-if="!isProfile" class="d-flex justify-space-between border-bottom pb-4 mb-2">
      <a @click="$router.go(-1)" class="d-flex align-center text-dark-gray font-weight-600">
        <v-icon right class="page-description ml-0 mr-2">mdi-chevron-left</v-icon> Назад
      </a>
      <div class="page-description error--text font-weight-600 pointer" @click="dialogDeleteShow = true">
        Удалить
      </div>
    </div>

    <div class="row">
      <div class="col-12 pb-0 d-flex justify-space-between">
        <div v-if="isProfile" class="font-weight-600 success--text">Активен</div>
        <v-switch v-else v-model="active" class="v-input--reverse mt-0"
                  hide-details
                  :color="active?'success':'error'">
          <div slot="label" class="page-description font-weight-600"
               :class="active?'success--text':'error--text'">Активен</div>
        </v-switch>
<!--        <div v-if="isProfile">-->
<!--          <v-icon color="#25325F" class="pointer">mdi-pencil</v-icon>-->
<!--        </div>-->
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-12">
        <h2 class="page-title">{{title}}</h2>
      </div>
    </div>

    <div class="row mt-4">
      <div v-if="user.phone" class="col-md-4 py-0">
        <table class="profile-table">
          <tr v-if="user.gender">
            <td class="profile-table__header">Пол</td>
            <td class="profile-table__content">{{user.gender === GENDERS.MALE ? 'М': 'Ж'}}</td>
          </tr>
          <tr v-if="false">
            <td class="profile-table__header">Город</td>
            <td class="profile-table__content">Москва?</td>
          </tr>
          <tr v-if="user.phone">
            <td class="profile-table__header">Телефон</td>
            <td class="profile-table__content">
              <div>{{user.phone | VueMaskFilter('+#-(###)-###-##-##')}}</div>
            </td>
          </tr>
        </table>
      </div>

      <div class="col-md-4 py-0">
        <table class="profile-table">
          <tr>
            <td class="profile-table__header">Почта</td>
            <td class="profile-table__content">{{user.email}}</td>
          </tr>
          <tr v-if="user.position">
            <td class="profile-table__header">Должность</td>
            <td class="profile-table__content">
              <div>{{user.position}}</div>
<!--              <v-select v-else :items="[]" label="Должность" dense solo hide-details/>-->
            </td>
          </tr>
          <tr v-if="false">
            <td class="profile-table__header">Компания</td>
            <td class="profile-table__content">ООО “Люкс Резорт”?</td>
          </tr>
        </table>
      </div>
    </div>

    <v-dialog v-model="dialogDeleteShow" max-width="600px">
      <v-card class="pa-8 pa-sm-16">
        <h2>Удалить?</h2>
        <div class="mt-6">
          Вы уверены, что хотите удалить кандидата?
          <br>
          Действие не может быть отменено.
        </div>
        <div class="mt-12 d-flex justify-center">
          <v-btn @click="dialogDeleteShow = false" class="px-10"
                 large outlined text>Отмена</v-btn>
          <v-btn @click="onDelete" class="ml-4 px-10"
                 color="error" large :loading="loading.delete">
            Удалить
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { VueMaskFilter, VueMaskDirective } from 'v-mask';
import { mapActions, mapGetters } from 'vuex';
import { MESSAGES } from '@/common/constants/errorMessages';
import { GENDERS } from '@/common/constants/genders';
import { maxLength, required } from 'vuelidate/lib/validators';
import { firstnameValidation, lastnameValidation, phoneValidation } from '@/common/validationHelpers';
import { CITY, FIRST_NAME, LAST_NAME } from '@/common/constants/validations';

export default {
  name: 'ProfileData',
  validations: {
    form: {
      last_name: { required, lastnameValidation, maxLength: maxLength(LAST_NAME.MAX_LENGTH) },
      first_name: { required, firstnameValidation, maxLength: maxLength(FIRST_NAME.MAX_LENGTH) },
      phone: { required, phoneValidation },
      date_of_birth: { required },
      city: { required, maxLength: maxLength(CITY.MAX_LENGTH) },
      gender: { required },
    },
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  directives: {
    mask: VueMaskDirective,
  },
  filters: {
    VueMaskFilter,
  },
  data: () => ({
    GENDERS,
    active: true,
    dialogDeleteShow: false,
    edited: false,
    form: {
      last_name: '',
      first_name: '',
      phone: '',
      date_of_birth: '',
      city: '',
      position: { text: 'Без должности', value: 'null' },
      gender: '',
    },
  }),
  computed: {
    ...mapGetters({
      errors: 'users/candidate/errors',
      loading: 'users/candidate/loading',
    }),
    isProfile() {
      return this.$route.name === 'profile';
    },
    title() {
      let text = `${this.user.last_name} ${this.user.first_name}`;
      if (this.user.date_of_birth) {
        const years = this.$moment().diff(this.user.date_of_birth, 'years');
        if (years) {
          text += `, ${years} ${this.$declOfNum(years, 'год', 'года', 'лет')}`;
        }
      }
      return text;
    },

    phoneErrors() {
      const errors = [];
      if (!this.$v.form.phone.$dirty) return errors;
      if (!this.$v.form.phone.required) errors.push(MESSAGES.REQUIRED);
      if (!this.$v.form.phone.phoneValidation) errors.push(MESSAGES.INCORRECT_PHONE);

      return this.errors.find((err) => err[0] === 'phone')?.[1] ?? errors;
    },
  },
  methods: {
    ...mapActions({
      deleteCandidate: 'users/candidate/deleteCandidate',
    }),
    onDelete() {
      this.deleteCandidate().then(() => {
        this.$router.replace({ name: 'candidates' });
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables";
.profile-table {
  height: 100%;
  &__header {
    font-weight: 600;
    padding: 0.5rem 0;
    font-size: $font-sm;
    @media (min-width: $break-point-sm) {
      font-size: $font-default;
    }
  }
  &__content {
    padding-left: 3rem;
    font-size: $font-sm;
    @media (min-width: $break-point-sm) {
      font-size: $font-default;
    }
  }
}
</style>
