import managers from './managers';
import candidates from './candidates';
import candidate from './candidate';

export default {
  namespaced: true,
  modules: {
    managers,
    candidates,
    candidate,
  },
};
