<template>
  <div v-if="results && generalInfo">
    <div class="row mt-2 mt-md-8">
      <div class="col-md-8">

        <div class="d-flex align-sm-center flex-column flex-sm-row">
          <div class="font-weight-600 mr-4">Статус тестирования</div>
          <div class="badge font-xs mt-2 mt-sm-0 mr-4" :class="testingStatusBadgeClass(generalInfo.test_status_code)">
            {{generalInfo.test_status_title}}
          </div>
          <div class="mt-2 mt-sm-0">
            <span class="font-weight-600 mr-2">Время</span>
            <span>{{generalInfo.time}}</span>
          </div>
        </div>

        <div v-if="generalInfo.tests" class="mt-2 mt-sm-8">
          <div class="row test-info" v-for="test in generalInfo.tests" :key="test.id">
            <div class="col-sm-8">
              <div class="test-info__card" :class="{disabled: test.answers_count !== test.questions_count}">
                <div class="font-weight-600">{{test.title}}</div>

                <div class="d-flex">
                  <div class="font-sm mr-8">
                    {{test.answers_count}} / {{ test.questions_count }}
                    <template v-if="hasAnswersStatistic(test)">
                      (<!--
                    --><span class="success--text">{{ test.correct_answers_count }}</span>
                      /
                      <span class="error--text">{{  test.answers_count - test.correct_answers_count}}</span><!--
                    -->)
                    </template>
                  </div>
                  <div class="d-flex align-center">
                    <v-icon class="icon icon-sm mr-2">mdi-alarm</v-icon>
                    {{test.time}}
                  </div>
                </div>
              </div>
            </div>

            <div v-if="!isProfile" class="col-sm-4 d-flex align-center pt-0 pt-sm-5">
              <div>
                <v-btn v-if="test.status_code === TESTING_STATUSES.COMPLETED && false"
                       @click="dialogDownloadPDFShow = true"
                       class="pa-sm-5" color="primary" outlined>
                  Скачать PDF-отчет
                </v-btn>
                <v-btn v-else-if="test.status_code === TESTING_STATUSES.NOT_SEND"
                       color="primary" @click="send(test)"
                       :loading="loading.createSession"
                       :disabled="loading.createSession"
                       class="pa-sm-5" outlined>Отправить тест</v-btn>
                <v-btn v-else-if="test.status_code === TESTING_STATUSES.NOT_COMPLETED"
                       color="primary" @click="send(test)"
                       :loading="loading.createSession"
                       :disabled="loading.createSession"
                       class="pa-sm-5" outlined>Отправить повторно</v-btn>
              </div>
            </div>
          </div>

          <div v-if="!isProfile && generalInfo.test_status_code === TESTING_STATUSES.COMPLETED" class="mt-sm-8">
            <v-btn v-if="false" class="mt-4 mt-sm-0 mr-4"
                   @click="dialogShareResultsShow = true"
                   color="primary" large>
              Поделиться с кандидатом
            </v-btn>
            <v-btn class="mt-4 mt-sm-0" color="primary" large @click="downloadPdf">
              Скачать PDF-отчет
            </v-btn>
          </div>
        </div>

      </div>
    </div>

    <template v-if="!isProfile">
      <v-dialog v-model="dialogDownloadPDFShow" max-width="700px">
        <v-card class="pa-8 pa-sm-16">
          <v-btn class="button-close ma-4" icon @click="dialogDownloadPDFShow = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <h2>Скачать PDF-отчет</h2>

          <form @submit.prevent="downloadPdf">
            <div class="font-weight-600 mt-8">Отражать в отчете:</div>

            <div class="row">
              <div class="col-sm-6">
                <v-switch v-model="downloadForm.riskFactors" label="Факторы риска" color="primary" hide-details/>
                <v-switch v-model="downloadForm.attentionZone" label="Зона внимания" color="primary" hide-details/>
                <v-switch v-model="downloadForm.complianceReport" label="Отчет соответствия" color="primary"
                          hide-details/>
                <v-switch v-model="downloadForm.personalData" label="Персональные данные" color="primary" hide-details/>
              </div>
              <div class="col-sm-6">
                <v-switch v-model="downloadForm.scales" label="Графики и шкалы" color="primary" hide-details/>
                <v-switch v-model="downloadForm.complianceRating" label="Рейтинг соответствия" color="primary"
                          hide-details/>
                <v-switch v-model="downloadForm.interpretation" label="Интерпретацию" color="primary" hide-details/>
              </div>
            </div>

            <div class="row mt-8">
              <div class="col-md-6 mx-auto">
                <v-btn type="submit" class="w-100" x-large color="primary">
                  Скачать PDF-отчет
                </v-btn>
              </div>
            </div>
          </form>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogShareResultsShow" max-width="700px">
        <v-card class="pa-8 pa-sm-16">
          <v-btn class="button-close ma-4" icon @click="dialogShareResultsShow = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <h2>Поделиться результатами <br>с кандидатом:</h2>

          <div class="mt-8">
            <v-switch label="В pdf документе" color="primary" hide-details/>
            <v-switch label="В личном кабинете кандидата" color="primary" hide-details/>
          </div>

          <form @submit.prevent="">
            <div class="font-weight-600 mt-8">Отражать в отчете:</div>
            <div class="row">
              <div class="col-sm-6">
                <v-switch v-model="downloadForm.riskFactors" label="Факторы риска" color="primary" hide-details/>
                <v-switch v-model="downloadForm.attentionZone" label="Зона внимания" color="primary" hide-details/>
                <v-switch v-model="downloadForm.complianceReport" label="Отчет соответствия" color="primary"
                          hide-details/>
                <v-switch v-model="downloadForm.personalData" label="Персональные данные" color="primary" hide-details/>
              </div>
              <div class="col-sm-6">
                <v-switch v-model="downloadForm.scales" label="Графики и шкалы" color="primary" hide-details/>
                <v-switch v-model="downloadForm.complianceRating" label="Рейтинг соответствия" color="primary"
                          hide-details/>
                <v-switch v-model="downloadForm.interpretation" label="Интерпретацию" color="primary" hide-details/>
              </div>
            </div>

            <div class="row mt-8">
              <div class="col-md-6 mx-auto">
                <v-btn type="submit" class="w-100" x-large color="primary">
                  Поделиться с кандидатом
                </v-btn>
              </div>
            </div>
          </form>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { TESTING_STATUSES } from '@/common/constants/testingStatuses';

export default {
  name: 'GeneralInformation',
  data: () => ({
    TESTING_STATUSES,
    dialogDownloadPDFShow: false,
    downloadForm: {
      riskFactors: true,
      attentionZone: false,
      complianceReport: false,
      personalData: false,
      scales: true,
      complianceRating: true,
      interpretation: false,
    },
    dialogShareResultsShow: false,
  }),
  computed: {
    ...mapGetters({
      results: 'users/candidate/results',
      loading: 'testing/session/loading',
    }),
    generalInfo() {
      return this.results.general_info;
    },
    isProfile() {
      return this.$route.name === 'profile';
    },
  },
  methods: {
    ...mapActions({
      createTestingSession: 'testing/session/createTestingSession',
      loadCandidatePdf: 'users/candidate/loadCandidatePdf',
      loadCandidateResults: 'users/candidate/loadCandidateResults',
    }),
    testingStatusBadgeClass(status) {
      switch (status) {
        case TESTING_STATUSES.COMPLETED: return 'badge-success';
        case TESTING_STATUSES.NOT_COMPLETED: return 'badge-primary';
        case TESTING_STATUSES.IN_PROGRESS: return 'badge-warning';
        case TESTING_STATUSES.NOT_SEND: return 'badge-error';
        case TESTING_STATUSES.NOT_STARTED: return '';
        default: return 'd-none';
      }
    },
    send(test) {
      const data = {
        tests: [test.test_code],
        candidate: parseInt(this.$route.params.id, 10),
      };
      this.createTestingSession(data).then(() => {
        this.loadCandidateResults(data.candidate);
      });
    },
    downloadFile(fileLink) {
      const link = document.createElement('a');
      link.setAttribute('download', true);
      link.setAttribute('href', fileLink);
      // link.setAttribute('target', '_blank');
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    downloadPdf() {
      this.loadCandidatePdf(parseInt(this.$route.params.id, 10)).then((resp) => {
        if (resp.link) {
          this.downloadFile(resp.link);
        }
      });
    },
    /**
     * @param {object} test
     * @return {boolean}
     * */
    hasAnswersStatistic(test) {
      return test.test_code.toLowerCase().includes('logic');
    },
  },
};
</script>
<style scoped lang="scss">
@import "src/assets/styles/variables";
.test-info {
  &__card {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05),
                2px 0 4px rgba(0, 0, 0, 0.05),
                -2px 0px 2px rgba(3, 3, 15, 0.05);
    border-radius: 0.25rem;
    padding: 1rem 1rem;
    margin-top: 0.75rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (min-width: $break-point-md) {
      flex-direction: row;
      align-items: center;
    }
    &.disabled {
      opacity: 0.5;
    }
  }
}
</style>
