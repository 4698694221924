<template>
  <div v-if="results && (riskFactors || attentionFactor)">
    <div v-if="false" class="row mt-2 mt-md-8 pb-6 border-bottom">
      <div class="col-12">
        <h3 class="profile-board__title">Отчет соответствия</h3>
      </div>
      <div class="col-md-3">
        <PieChart :data="chartdata" />
      </div>
      <div class="col-md-8 offset-sm-1">
        <div class="pb-6 border-bottom">
          <div class="position-candidate">
            <div class="d-flex justify-space-between">
              <div class="d-flex align-center">
                <div class="color-box mr-4" :style="{background: candidatePosition.color}"/>
                <div class="font-weight-600 title">{{candidatePosition.title}}</div>
              </div>
              <div class="position-candidate__number">{{candidatePosition.value}}%</div>
            </div>

            <div>
              <v-icon right class="ma-0 mr-3 icon-sm" color="primary">mdi-alert-circle</v-icon>
              Должность, на которую тестировался кандидат
            </div>
          </div>
        </div>

        <div class="mt-6 legend">
          <div class="legend__item" v-for="(item, ind) in chartdata.labels" :key="ind">
            <div class="d-flex align-center">
              <div class="color-box mr-4" :style="{background: chartdata.datasets[0].backgroundColor[ind]}"/>
              <div>{{item}}</div>
            </div>
            <div>{{chartdata.datasets[0].data[ind]}}%</div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="riskFactors && riskFactors.factors" class="row mt-2 mt-md-8">
      <div class="col-md-7">
        <h3 class="page-title d-inline-block relative">
          Факторы риска <span class="counter">{{ riskFactors.factors.length }}</span>
        </h3>

        <div v-if="!riskFactors.factors.length" class="page-description mt-4">Отсутствуют</div>

        <div v-for="(factor, ind) in riskFactors.factors" :key="ind">
          <div class="page-subtitle mt-4">{{factor.title}}</div>
          <div class="page-description mt-3">{{factor.description}}</div>
        </div>
      </div>
    </div>

    <div v-if="attentionFactor && attentionFactor.factors" class="row mt-2 mt-md-8">
      <div class="col-md-7">
        <h3 class="page-title d-inline-block relative ">
          Зоны внимания <span class="counter">{{attentionFactor.factors.length}}</span>
        </h3>

        <div v-if="!attentionFactor.factors.length" class="page-description mt-4">Отсутствуют</div>

        <div v-for="(factor, ind) in attentionFactor.factors" :key="ind">
          <div class="page-subtitle mt-4">{{factor.title}}</div>
          <div class="page-description mt-3">{{factor.description}}</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PieChart from '@/components/ui/PieChart';

export default {
  name: 'ComplianceReport',
  components: {
    PieChart,
  },
  data: () => ({
    candidatePosition: {
      id: 11,
      title: 'Менеджер КС',
      color: '#6CBDBF',
      value: '20',
    },
    chartdata: {
      hoverBackgroundColor: 'red',
      hoverBorderWidth: 10,
      labels: [
        'Веб-дизайнер',
        'HR-специалист',
        'Аналитик данных',
        'Менеджер по сопровождения',
        'Бухгалтер',
        'Менеджер КС',
        'Разработчик',
        'Бухгалтер',
        'HR-специалист',
        'Разработчик',
      ],
      datasets: [
        {
          label: 'Data One',
          backgroundColor: [
            '#6CA1BF',
            '#ED6E85',
            '#F2A254',
            '#F8CE6B',
            '#F8EA6B',
            '#6CBDBF',
            '#84E97B',
            '#D8FFA6',
            '#736CBF',
            '#6C83BF',
          ],
          data: [30, 25, 4, 9, 3, 20, 9, 3, 25, 9],
        },
      ],
    },
  }),
  computed: {
    ...mapGetters({
      results: 'users/candidate/results',
    }),
    riskFactors() {
      return this.results.risk_factors;
    },
    attentionFactor() {
      return this.results.attantion_factor;
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables";

.legend {
  display: flex;
  flex-wrap: wrap;
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.75rem;
    width: 50%;
    &:nth-child(2n) {
      padding-left: 2rem;
    }
    &:nth-child(2n+1) {
      padding-right: 2rem;
    }
  }
}

.position-candidate {
  border: 1px solid $color-blue;
  box-sizing: border-box;
  border-radius: 10px;
  background: $color-very-light-gray;
  padding: 1.5rem;
  width: max-content;
  font-size: $font-sm;
  &__number {
    font-weight: bold;
    color: $color-blue;
    margin-left: 2rem;
    font-size: 18px;
  }
}
</style>
