<template>
  <div class="mmpi">
    <h2 class="question__title mb-8">{{testData.question.question}}</h2>
    <div>
      <div class="row">
        <div class="col-md-6 py-2" v-for="answer in testData.answer_options" :key="answer.id">
          <v-btn class="w-100 justify-start" @click="onClickAnswer(answer)"
                 :elevation="0"
                 :disabled="loading"
                 :color="(selectedAnswer && selectedAnswer.id === answer.id) ? 'primary' : ''"
                 :outlined="selectedAnswer && selectedAnswer.id === answer.id"
                 large>

            {{answer.title}}
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'MMPI',
  computed: {
    ...mapGetters({
      testData: 'testing/testing/testData',
      loading: 'testing/testing/loading',
      selectedAnswer: 'testing/testing/selectedAnswer',
    }),
  },
  methods: {
    ...mapActions({
      sendAnswer: 'testing/testing/sendAnswer',
      setSelectedAnswer: 'testing/testing/setSelectedAnswer',
    }),
    onClickAnswer(answer) {
      this.setSelectedAnswer(answer);
      this.sendAnswer();
    },
  },
};
</script>
<style scoped lang="scss">
.mmpi {
  min-height: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
