import api from '@/common/api';

const initialState = () => ({
  loading: {
    candidate: false,
    results: false,
    delete: false,
    edit: false,
    loadPdf: false,
  },
  errors: {
    candidate: [],
    results: [],
    delete: [],
    edit: [],
    loadPdf: [],
  },
  candidate: null,
  results: null,
});

export default {
  namespaced: true,
  state: initialState,
  actions: {
    async loadCandidate({ commit }, id) {
      commit('loadCandidateRequest');
      try {
        const response = await api.get(`/users/candidates/${id}/`);
        commit('loadCandidateSuccess', response);
      } catch (err) {
        commit('loadCandidateFailure', Object.entries(err.data));
        throw err;
      }
    },
    async loadCandidateResults({ commit, dispatch }, id) {
      commit('loadCandidateResultsRequest');
      try {
        const response = await api.get(`/users/candidates/${id}/get-test-result/`);
        commit('loadCandidateResultsSuccess', response);
        dispatch('profile/tabs/setTabs', response, { root: true });
      } catch (err) {
        commit('loadCandidateResultsFailure', Object.entries(err.data));
        throw err;
      }
    },

    async deleteCandidate({ getters, commit }) {
      commit('deleteCandidateRequest');
      try {
        await api.delete(`/users/candidates/${getters.candidate.id}/`);
        commit('deleteCandidateSuccess');
      } catch (err) {
        commit('deleteCandidateFailure', Object.entries(err.data));
        throw err;
      }
    },
    async editCandidate({ getters, commit }) {
      commit('editCandidateRequest');
      try {
        await api.delete(`/users/candidates/${getters.candidate.id}/`);
        commit('editCandidateSuccess');
      } catch (err) {
        commit('editCandidateFailure', Object.entries(err.data));
        throw err;
      }
    },

    async loadCandidatePdf({ commit }, id) {
      commit('loadCandidatePdfRequest');
      const data = { candidate: id };
      try {
        const response = await api.post('/users/candidates/download-pdf/', data);
        commit('loadCandidatePdfSuccess');
        return response;
      } catch (err) {
        commit('loadCandidatePdfFailure', Object.entries(err.data));
        throw err;
      }
    },

    resetState({ commit }) {
      commit('resetState');
    },
  },
  mutations: {
    loadCandidateRequest(state) {
      state.loading.candidate = true;
      state.errors.candidate = [];
    },
    loadCandidateSuccess(state, candidate) {
      state.candidate = candidate;
      state.loading.candidate = false;
    },
    loadCandidateFailure(state, error) {
      state.errors.candidate = error;
      state.loading.candidate = false;
    },

    loadCandidateResultsRequest(state) {
      state.results = [];
      state.loading.results = true;
      state.errors.results = [];
    },
    loadCandidateResultsSuccess(state, results) {
      state.results = results;
      state.loading.results = false;
    },
    loadCandidateResultsFailure(state, error) {
      state.errors.results = error;
      state.loading.results = false;
    },

    deleteCandidateRequest(state) {
      state.loading.delete = true;
      state.errors.delete = [];
    },
    deleteCandidateSuccess(state) {
      state.loading.delete = false;
      state.candidate = null;
      state.results = null;
    },
    deleteCandidateFailure(state, error) {
      state.errors.delete = error;
      state.loading.delete = false;
    },

    editCandidateRequest(state) {
      state.loading.edit = true;
      state.errors.edit = [];
    },
    editCandidateSuccess(state) {
      state.loading.edit = false;
    },
    editCandidateFailure(state, error) {
      state.errors.edit = error;
      state.loading.edit = false;
    },

    loadCandidatePdfRequest(state) {
      state.loading.loadPdf = true;
      state.errors.loadPdf = [];
    },
    loadCandidatePdfSuccess(state) {
      state.loading.loadPdf = false;
    },
    loadCandidatePdfFailure(state, error) {
      state.errors.loadPdf = error;
      state.loading.loadPdf = false;
    },

    resetState(state) {
      const initial = initialState();
      Object.keys(initial).forEach((key) => { state[key] = initial[key]; });
    },
  },
  getters: {
    loading: (state) => state.loading,
    errors: (state) => state.errors,
    candidate: (state) => state.candidate,
    results: (state) => state.results,
  },
};
