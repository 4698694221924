import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store';

import { ADMIN, CANDIDATE, MANAGER } from '@/common/constants/userTypes';

// Layouts
import AuthLayout from '@/layouts/AuthLayout';
import DashboardLayout from '@/layouts/DashboardLayout';

// Pages
import Home from '@/views/Home';

import Test from '@/views/Test';

import Login from '@/views/Login';
import Profile from '@/views/Profile';

const Managers = () => import('@/views/managers/Managers');

const Candidates = () => import('@/views/candidates/Candidates');
const Candidate = () => import('@/views/candidates/_id');

const ResetPassword = () => import('@/views/ResetPassword');
const UpdatePassword = () => import('@/views/UpdatePassword');

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    redirect: { name: 'login' },
  },
  {
    path: '/',
    component: AuthLayout,
    name: 'auth-layout',
    children: [
      {
        path: '/login',
        name: 'login',
        component: Login,
        meta: { onlyPublic: true },
      },
      {
        path: '/reset-password',
        name: 'reset-password',
        component: ResetPassword,
        meta: { onlyPublic: true },
      },
      {
        path: '/update-password',
        name: 'update-password',
        component: UpdatePassword,
        meta: { onlyPublic: true },
      },
      {
        path: '/test/:token',
        name: 'test',
        component: Test,
      },
    ],
  },
  {
    path: '/',
    name: 'dashboard-layout',
    component: DashboardLayout,
    meta: { allowed: [ADMIN, MANAGER, CANDIDATE] },
    children: [
      {
        path: 'profile',
        name: 'profile',
        component: Profile,
        meta: { allowed: [ADMIN, MANAGER, CANDIDATE] },
      },
      {
        path: 'managers',
        name: 'managers',
        component: Managers,
        meta: { allowed: [ADMIN] },
      },
      {
        path: 'candidates',
        name: 'candidates',
        component: Candidates,
        meta: { allowed: [ADMIN, MANAGER] },
      },
      {
        path: 'candidates/:id',
        name: 'candidates-id',
        component: Candidate,
        meta: { allowed: [ADMIN, MANAGER, CANDIDATE] },
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const token = store.getters['auth/token'];
  let loggedIn = store.getters['auth/loggedIn'];
  let user = store.getters['auth/user'];

  if (!loggedIn) {
    if (token) {
      try {
        await store.dispatch('auth/loadMe');
        user = store.getters['auth/user'];
        loggedIn = store.getters['auth/loggedIn'];
      } catch (e) {
        if (to.meta.allowed) return next({ name: 'login' });
      } finally {
        store.commit('auth/setInitialized');
      }
    }
  }

  if (!loggedIn && to.meta.allowed) return next({ name: 'login' });

  if (loggedIn && to.meta?.onlyPublic) return next({ name: 'profile' });

  if (loggedIn) {
    if (!to.meta.allowed) return next();

    if (to.meta.allowed.some((t) => t === user.user_type)) {
      return next();
    }
    if (user.user_type === ADMIN) {
      return next({ name: 'managers' });
    }
    if (user.user_type === MANAGER) {
      return next({ name: 'candidates' });
    }
    return next({ name: 'profile' });
  }

  next();
});

export default router;
