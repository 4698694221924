<template>
  <div class="page">
    <div class="profile-board">
      <ProfileData :user="user" />
    </div>

    <div v-if="selectedTab && user.user_type === CANDIDATE" class="profile-board mt-8 pt-2">
      <ProfileTabs />
      <component v-bind:is="selectedTab.component" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { CANDIDATE } from '@/common/constants/userTypes';
import ProfileData from '@/components/profile/ProfileData';
import ProfileTabs from '@/components/profile/ProfileTabs';
import GeneralInformation from '@/components/profile/tabs/GeneralInformation';
import ComplianceReport from '@/components/profile/tabs/ComplianceReport';
import Scales from '@/components/profile/tabs/Scales';
import Competencies from '@/components/profile/tabs/Competencies';
import StressTolerance from '@/components/profile/tabs/StressTolerance';
import MotivatorsAndDestructors from '@/components/profile/tabs/MotivatorsAndDestructors';
import TeamRoles from '@/components/profile/tabs/TeamRoles';
import ManagementStyle from '@/components/profile/tabs/ManagementStyleV2';
import Logic from '@/components/profile/tabs/Logic';
import ProfInterests from '@/components/profile/tabs/ProfInterests';

export default {
  name: 'Profile',
  components: {
    ProfileData,
    ProfileTabs,
    GeneralInformation,
    ComplianceReport,
    Scales,
    Competencies,
    StressTolerance,
    MotivatorsAndDestructors,
    TeamRoles,
    ManagementStyle,
    Logic,
    ProfInterests,
  },
  data: () => ({
    CANDIDATE,
  }),
  computed: {
    ...mapGetters({
      selectedTab: 'profile/tabs/selectedTab',
      user: 'auth/user',
    }),
  },
  methods: {
    ...mapActions({
      loadCandidateResults: 'users/candidate/loadCandidateResults',
      resetStateCandidate: 'users/candidate/resetState',
      resetStateTabs: 'profile/tabs/resetState',
    }),
  },
  created() {
    if (this.user.user_type === CANDIDATE) {
      this.loadCandidateResults(this.user.id);
    }
  },
  beforeDestroy() {
    this.resetStateCandidate();
    this.resetStateTabs();
  },
};
</script>
