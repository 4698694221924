const initialState = () => ({
  tabs: [],
  selectedTab: null,
});

export default {
  namespaced: true,
  state: initialState,
  actions: {
    setTabs({ commit }, data) {
      const tabs = [];

      if (data.general_info) {
        tabs.push({ title: 'Общая информация', component: 'GeneralInformation' });
      }
      if (data.attantion_factor || data.risk_factors) {
        tabs.push({ title: 'Отчет соответствия', component: 'ComplianceReport' });
      }
      if (data.scales) {
        tabs.push({ title: 'Шкалы', component: 'Scales' });
      }
      if (data.competences) {
        tabs.push({ title: 'Компетенции', component: 'Competencies' });
      }
      if (data.stress_tolerance) {
        tabs.push({ title: 'Стрессоустойчивость', component: 'StressTolerance' });
      }
      if (data.motivators_and_destructors) {
        tabs.push({ title: 'Мотиваторы и Деструкторы', component: 'MotivatorsAndDestructors' });
      }
      if (data.team_roles) {
        tabs.push({ title: 'Роли в команде', component: 'TeamRoles' });
      }
      if (data.leadership_styles) {
        tabs.push({ title: 'Стиль управления', component: 'ManagementStyle' });
      }
      if (data.logic) {
        tabs.push({ title: 'Логика', component: 'Logic' });
      }
      if (data.prof_interests) {
        tabs.push({ title: 'Карта интересов', component: 'ProfInterests' });
      }

      commit('setTabs', tabs);
      if (tabs.length) {
        commit('setSelectedTab', tabs[0]);
      }
    },
    setSelectedTab({ commit }, tab) {
      commit('setSelectedTab', tab);
    },
    resetState({ commit }) {
      commit('resetState');
    },
  },
  mutations: {
    setTabs(state, tabs) {
      state.tabs = tabs;
    },
    setSelectedTab(state, tab) {
      state.selectedTab = tab;
    },
    resetState(state) {
      const initial = initialState();
      Object.keys(initial).forEach((key) => { state[key] = initial[key]; });
    },
  },
  getters: {
    tabs: (state) => state.tabs,
    selectedTab: (state) => state.selectedTab,
  },
};
