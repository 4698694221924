export const MESSAGES = {
  REQUIRED: 'Обязательное поле',
  MIN_LENGTH: (length) => `Минимум ${length} символов`,
  MAX_LENGTH: (length) => `Максимум ${length} символов`,
  INCORRECT_FIRSTNAME: 'Некорректное имя',
  INCORRECT_LASTNAME: 'Некорректная фамилия',
  INCORRECT_EMAIL: 'Некорректный адрес эл. почты',
  INCORRECT_PHONE: 'Некорректный номер телефона',
  EMAIL_MATCH: 'Не существует пользователя с таким адресом эл. почты',
  START_SPACES: 'Нельзя использовать пробел в начале',
  EMAIL_DOES_NOT_EXIST: 'С указанным адресом электронной почты не связан ни один активный пользователь',
};
