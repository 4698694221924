<template>
  <div class="auth-card text-center">
    <h2 class="auth-card__title mb-8">
      К сожалению произошла ошибка, возможно неверная ссылка
    </h2>
  </div>
</template>

<script>
export default {
  name: 'Error',
};
</script>
