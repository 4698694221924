<script>
import { Pie } from 'vue-chartjs';

export default {
  name: 'PieChart',
  extends: Pie,
  props: ['data', 'options'],
  mounted() {
    this.renderChart(this.data, {
      borderWidth: '10px',
      legend: {
        display: false,
      },
    });
  },
};
</script>

<style scoped>

</style>
