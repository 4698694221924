import axios from 'axios';
import { getToken } from '@/common/jwt.service';

const baseURL = process.env.VUE_APP_BASE_URL || 'https://horeca-dev.bsl.dev/api';
const instance = axios.create({ baseURL });

instance.interceptors.request.use((config) => {
  config.headers['Accept-Language'] = 'ru';
  const token = getToken();
  if (token) config.headers.Authorization = `Token ${token}`;
  return config;
});

instance.interceptors.response.use(
  (resp) => resp.data,
  (err) => {
    if (err.response?.status >= 500) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        data: { server: [`Ошибка статус ${err.status}`] },
      });
    }
    if (err.response) {
      return Promise.reject(err.response);
    }
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({
      data: { not_server_response: ['Ошибка соединения с сервером'] },
    });
  },
);

export default instance;
