import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import './plugins/moment';
import './plugins/declOfNum';
import './plugins/vuelidate';
import './plugins/vue-meta';
import vuetify from './plugins/vuetify';
import '@/assets/styles/main.scss';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
