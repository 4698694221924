<template>
  <v-app>
    <v-navigation-drawer app v-model="drawer" clipped>
      <v-list-item class="mt-2 d-lg-none">
        <v-app-bar-nav-icon @click="drawer = !drawer" class="mr-5" />
        <v-toolbar-title>
          <router-link :to="{name: 'home'}"><img src="@/assets/images/logo.svg" alt="Главная"></router-link>
        </v-toolbar-title>
      </v-list-item>

      <v-list dense nav class="mt-5">
        <v-list-item v-for="(item, ind) in itemsMenu" :key="ind"
                     color="primary" :to="{name: item.to}">
          <div class="mr-5">
            <v-icon>{{ item.icon }}</v-icon>
          </div>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app clipped-left>
      <v-app-bar-nav-icon @click="drawer = !drawer" />
      <v-toolbar-title>
        <router-link :to="{name: 'home'}"><img src="@/assets/images/logo.svg" alt="Главная"></router-link>
      </v-toolbar-title>

      <v-spacer />

      <v-btn icon @click="onLogout" :loading="loading" :disabled="loading">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main class="bg-main h-100">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ADMIN, CANDIDATE, MANAGER } from '@/common/constants/userTypes';

export default {
  name: 'DashboardLayout',
  data: () => ({
    drawer: false,
    items: [
      {
        title: 'Личный кабинет',
        icon: 'mdi-account-circle',
        to: 'profile',
        allowed: [ADMIN, MANAGER, CANDIDATE],
      },
      {
        title: 'Корпоративные клиенты',
        icon: 'mdi-account-multiple',
        to: 'managers',
        allowed: [ADMIN],
      },
      {
        title: 'Кандидаты',
        icon: 'mdi-account-multiple',
        to: 'candidates',
        allowed: [ADMIN, MANAGER],
      },
    ],
  }),
  computed: {
    ...mapGetters({
      loggedIn: 'auth/loggedIn',
      loading: 'auth/loading',
      user: 'auth/user',
      notifications: 'notifications/notifications',
    }),
    itemsMenu() {
      if (!this.user) return [];
      if (this.user.user_type === MANAGER) {
        return this.items.filter((item) => item.allowed.some((t) => t === MANAGER));
      }
      if (this.user.user_type === CANDIDATE) {
        return this.items.filter((item) => item.allowed.some((t) => t === CANDIDATE));
      }
      return this.items;
    },
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout',
      removeNotification: 'notifications/remove',
    }),
    onLogout() {
      this.logout().then(() => {
        this.$router.push({ name: 'home' });
      });
    },
  },
  watch: {
    loggedIn(newVal) {
      if (newVal) this.$router.push({ name: 'home' });
    },
  },
};
</script>
<style>
.h-100 {
  height: 100%;
}
</style>
