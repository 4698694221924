<template>
  <div>
    <h2 v-html="question" class="question__title mb-8 "
        :class="{'question__title-font-default': question.length > 150}" />

    <div class="question__image mb-12">
      <img :src="testData.question.image" alt="image">
    </div>
    <div class="row">
      <div class="col-md-4 py-2" v-for="answer in testData.answer_options" :key="answer.id">
        <v-btn class="answer__btn-image pa-0" @click="onClickAnswer(answer)"
               :elevation="0"
               :disabled="loading"
               :color="(selectedAnswer && selectedAnswer.id === answer.id) ? 'primary' : ''"
               :outlined="selectedAnswer && selectedAnswer.id === answer.id"
               large>

          <img class="w-100 h-100" :src="answer.image" alt="image">
          <div class="answer__btn-image-title">
            {{answer.title}}
          </div>
        </v-btn>
      </div>
    </div>
    <div class="row mt-8">
      <div class="col-md-6 mx-auto">
        <v-btn class="btn-disabled-primary w-100" @click="sendAnswer"
               :elevation="0"
               :disabled="!selectedAnswer || loading"
               color="primary"
               large>

           Далее
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'NonVerbalLogic',
  computed: {
    ...mapGetters({
      testData: 'testing/testing/testData',
      loading: 'testing/testing/loading',
      selectedAnswer: 'testing/testing/selectedAnswer',
    }),
    question() {
      return this.testData.question.question.replace(/\n/g, '<br>');
    },
  },
  methods: {
    ...mapActions({
      sendAnswer: 'testing/testing/sendAnswer',
      setSelectedAnswer: 'testing/testing/setSelectedAnswer',
    }),
    onClickAnswer(answer) {
      this.setSelectedAnswer(answer);
    },
  },
};
</script>
