<template>
  <div class="question">
    <div class="progress-bar"><div class="progress" ref="progress"/></div>

    <div class="question__top-actions mb-8">
      <div class="top-actions__item">
        <div v-if="testData.is_can_go_back" class="d-flex align-center text-gray pointer" @click="backStep">
          <img class="mr-3" src="@/assets/images/icons/arrow-gray.svg" alt="Назад">
          <span>Назад</span>
        </div>
      </div>
      <div class="top-actions__item text-center">
        <span>{{testData.question_number}} </span>
        <span class="text-gray">/ {{testData.questions_count}}</span>
      </div>
      <div class="top-actions__item">
        <div v-if="testData.timer" class="badge border-none badge-success ml-auto"
             :class="timer>10000?'badge-success':'badge-error'">
          {{$moment(timer).format('mm:ss')}}
        </div>
      </div>
    </div>

    <component v-bind:is="activeTest" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { TEST_TYPES } from '@/common/constants/testTypes';
import MMPI from '@/components/tests/test-types/MMPI';
import NumericLogic from '@/components/tests/test-types/NumericLogic';
import VerbalLogic from '@/components/tests/test-types/VerbalLogic';
import NonVerbalLogic from '@/components/tests/test-types/NonVerbalLogic';
import ProfessionalInterests from '@/components/tests/test-types/ProfessionalInterests';

export default {
  name: 'Test',
  components: {
    MMPI,
    NumericLogic,
    VerbalLogic,
    NonVerbalLogic,
    ProfessionalInterests,
  },
  data: () => ({
    timerId: null,
    timer: 0,
  }),
  computed: {
    ...mapGetters({
      testData: 'testing/testing/testData',
    }),
    activeTest() {
      switch (this.testData?.test_type) {
        case TEST_TYPES.MMPI: return 'MMPI';
        case TEST_TYPES.NUMERIC_LOGIC: return 'NumericLogic';
        case TEST_TYPES.VERBAL_LOGIC: return 'VerbalLogic';
        case TEST_TYPES.NON_VERBAL_LOGIC: return 'NonVerbalLogic';
        case TEST_TYPES.PROF_INTERESTS: return 'ProfessionalInterests';
        default: return '';
      }
    },
  },
  methods: {
    ...mapActions({
      backStep: 'testing/testing/backStep',
      nextStep: 'testing/testing/nextStep',
    }),
    updateProgress() {
      if (this.testData.questions_count && this.testData.question_number) {
        const numTotal = ((this.testData.question_number - 1) / this.testData.questions_count) * 100;
        this.$refs.progress.style.width = `${Math.floor(numTotal)}%`;
      }
    },
    countdownTimer(callback) {
      this.timer = this.testData.timer * 1000;
      clearInterval(this.timerId);
      this.timerId = setInterval(() => {
        this.timer -= 1000;
        if (this.timer < 1000) {
          this.timer = 0;
          clearInterval(this.timerId);
          this.timerId = null;
          callback();
        }
      }, 1000);
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$store.state.testing.testing.testData.question_number': function () {
      this.updateProgress();
      if (this.testData.timer) {
        this.countdownTimer(this.nextStep);
      }
    },
  },
  mounted() {
    this.updateProgress();
    if (this.testData.timer) {
      this.countdownTimer(this.nextStep);
    }
  },
  beforeDestroy() {
    clearInterval(this.timerId);
  },
};
</script>
<style scoped lang="scss">
.progress-bar {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 0.5rem;
  background: #F5F4F4;
  & .progress {
    background: #18A0FB;
    width: 0;
    height: 100%;
    transition: width 0.5s ease;
  }
}

.border-none {
  border: none;
}

</style>
