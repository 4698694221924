import api from '@/common/api';

const initialState = () => ({
  loading: {
    load: false,
    create: false,
    delete: false,
    loadResults: false,
  },
  errors: {
    load: [],
    create: [],
    delete: [],
    loadResults: [],
  },
  count: 0,
  candidates: [],
  selected: [],
  filter: {
    page: 1,
    name: '',
    email: '',
    position: '',
    company: '',
    testing_status: [],
    /**
     * @type {null | string}
     * */
    from_date_joined: null,
    /**
     * @type {null | string}
     * */
    to_date_joined: null,
  },
  editedCandidate: null,
  dialogCreateShow: false,
  dialogDeleteShow: false,
});

export default {
  namespaced: true,
  state: initialState,
  actions: {
    async loadCandidates({ commit, getters }) {
      commit('loadCandidatesRequest');
      try {
        /**
         *
         * Проверяю если значение фильтра пустое, то не добавляю его в параметры запроса,
         * иначе валится бэк
         */
        const filter = {};
        Object.keys(getters.filter).forEach((key) => {
          if (key === 'page') return;
          if (Array.isArray(getters.filter[key])) {
            if (getters.filter[key].length) {
              filter[key] = getters.filter[key];
            }
            return;
          }
          if (getters.filter[key]) {
            filter[key] = getters.filter[key];
          }
        });

        const response = await api.post(`/users/candidates/filter/?page=${getters.filter.page}`, filter);
        commit('setCandidates', response.results);
        commit('setCount', response.count);
        commit('loadCandidatesSuccess');
      } catch (err) {
        commit('loadCandidatesFailure', Object.entries(err.data));
        throw err;
      }
    },
    async createCandidate({ commit, dispatch, getters }, data) {
      commit('createCandidateRequest');
      try {
        await api.post('/users/candidates/', data);
        commit('createCandidateSuccess');
        if (getters.filter.page === 1) {
          dispatch('loadCandidates');
        }
      } catch (err) {
        commit('createCandidateFailure', Object.entries(err.data));
        throw err;
      }
    },
    async setFilterPage({ commit, dispatch }, page) {
      commit('setFilterPage', page);
      await dispatch('loadCandidates');
    },
    async setFilterName({ commit, dispatch }, name) {
      commit('setFilterName', name);
      commit('setFilterPage', 1);
      await dispatch('loadCandidates');
    },
    async setFilterEmail({ commit, dispatch }, email) {
      commit('setFilterEmail', email);
      commit('setFilterPage', 1);
      await dispatch('loadCandidates');
    },
    /**
     * @param {any} context
     * @param {string[]} dates
     * */
    async setFilterJoinedDatesRange({ commit, dispatch }, dates) {
      commit('setFilterJoinedDatesRange', dates);
      commit('setFilterPage', 1);
      await dispatch('loadCandidates');
    },
    async setFilterPosition({ commit, dispatch }, position) {
      commit('setFilterPosition', position);
      commit('setFilterPage', 1);
      await dispatch('loadCandidates');
    },
    async setFilterCompany({ commit, dispatch }, payload) {
      commit('setFilterCompany', payload);
      commit('setFilterPage', 1);
      await dispatch('loadCandidates');
    },
    async setFilterTestingStatus({ commit }, statuses) {
      commit('setFilterTestingStatus', statuses);
    },
    async setFilterAll({ commit, dispatch }, params) {
      commit('setFilterPage', parseInt(params.page, 10) || 1);
      commit('setFilterName', params.name ?? '');
      commit('setFilterEmail', params.email ?? '');
      commit('setFilterPosition', params.position ?? '');
      commit('setFilterCompany', params.company ?? '');
      commit('setFilterTestingStatus', params.testingStatus ?? []);
      await dispatch('loadCandidates');
    },

    async editCandidateComment({ commit }, { id, text }) {
      commit('editCandidateCommentRequest', id);
      const data = {
        candidate: id,
        text,
      };
      try {
        const response = await api.post('/users/candidates/comment/', data);
        commit('editCandidateCommentSuccess', response);
      } catch (err) {
        commit('editCandidateCommentFailure', { id, error: Object.entries(err.data) });
        throw err;
      }
    },

    setCandidateTestingStatus({ commit }, { id, data }) {
      commit('setCandidateTestingStatus', { id, data });
    },

    clearError({ commit }) {
      commit('clearError');
    },
    setSelected({ commit }, id) {
      commit('setSelected', id);
    },
    allSelected({ commit }) {
      commit('allSelected');
    },
    clearSelected({ commit }) {
      commit('clearSelected');
    },
    setDialogCreateShow({ commit }, bool) {
      if (!bool) commit('setEditedCandidate', null);
      commit('setDialogCreateShow', bool);
    },
    setDialogDeleteShow({ commit }, bool) {
      if (!bool) commit('setEditedCandidate', null);
      commit('setDialogDeleteShow', bool);
    },
    deleteCandidateDialog({ commit }, candidate) {
      commit('setEditedCandidate', { ...candidate });
      commit('setDialogDeleteShow', true);
    },

    async deleteCandidates({ getters, commit, dispatch }, candidates) {
      if (!(getters.selected.length || candidates.length)) return;
      commit('deleteCandidatesRequest');
      try {
        const data = {
          candidates: getters.selected,
        };
        if (candidates) {
          data.candidates = candidates;
        }
        await api.delete('/users/candidates/bulk-delete/', { data });
        commit('clearSelected');
        dispatch('loadCandidates');
        commit('deleteCandidatesSuccess');
      } catch (err) {
        commit('deleteCandidatesFailure', Object.entries(err.data));
        throw err;
      }
    },

    async loadCandidatesResults({ commit, getters }) {
      commit('loadCandidatesResultsRequest');
      const data = {
        candidates: getters.selected,
      };
      try {
        const response = await api.post('/users/candidates/get-test-result/', data);
        commit('loadCandidatesResultsSuccess');
        return response;
      } catch (err) {
        commit('loadCandidatesResultsFailure', Object.entries(err.data));
        throw err;
      }
    },
  },
  mutations: {
    clearError(state) {
      state.errors = initialState().errors;
    },
    setCount(state, count) {
      state.count = count;
    },
    setCandidates(state, candidates) {
      state.candidates = candidates.map((c) => ({ ...c, loading: {}, errors: {} }));
    },
    setFilterPage(state, page) {
      state.filter.page = parseInt(page, 10);
    },
    setFilterName(state, name) {
      state.filter.name = name;
    },
    /**
     * @param {object} state
     * @param {string[]} dates
     * */
    setFilterJoinedDatesRange(state, dates) {
      // eslint-disable-next-line prefer-destructuring
      state.filter.from_date_joined = dates[0];
      // eslint-disable-next-line prefer-destructuring
      state.filter.to_date_joined = dates[1];
    },
    setFilterEmail(state, email) {
      state.filter.email = email;
    },
    setFilterPosition(state, position) {
      state.filter.position = position;
    },
    setFilterCompany(state, payload) {
      state.filter.company = payload;
    },
    setFilterTestingStatus(state, testingStatus) {
      state.filter.testing_status = testingStatus;
    },
    setSelected(state, id) {
      if (id || id === 0) {
        const itemFound = state.selected.some((item) => item === id);
        if (itemFound) {
          state.selected = state.selected.filter((item) => item !== id);
        } else {
          state.selected.push(id);
        }
      } else {
        state.selected = [];
      }
    },
    allSelected(state) {
      state.candidates.forEach((c) => {
        if (!state.selected.some((id) => c.id === id)) {
          state.selected.push(c.id);
        }
      });
    },
    clearSelected(state) {
      state.selected = [];
    },
    setEditedCandidate(state, candidate) {
      state.editedCandidate = candidate;
    },
    setDialogCreateShow(state, bool) {
      state.dialogCreateShow = bool;
    },
    setDialogDeleteShow(state, bool) {
      state.dialogDeleteShow = bool;
    },

    loadCandidatesRequest(state) {
      state.loading.load = true;
      state.errors.load = [];
    },
    loadCandidatesSuccess(state) {
      state.loading.load = false;
    },
    loadCandidatesFailure(state, error) {
      state.errors.load = error;
      state.loading.load = false;
    },

    createCandidateRequest(state) {
      state.loading.create = true;
      state.errors.create = [];
    },
    createCandidateSuccess(state) {
      state.loading.create = false;
    },
    createCandidateFailure(state, error) {
      state.errors.create = error;
      state.loading.create = false;
    },

    deleteCandidatesRequest(state) {
      state.loading.delete = true;
      state.errors.delete = [];
    },
    deleteCandidatesSuccess(state) {
      state.loading.delete = false;
    },
    deleteCandidatesFailure(state, error) {
      state.errors.delete = error;
      state.loading.delete = false;
    },

    loadCandidatesResultsRequest(state) {
      state.loading.loadResults = true;
      state.errors.loadResults = [];
    },
    loadCandidatesResultsSuccess(state) {
      state.loading.loadResults = false;
    },
    loadCandidatesResultsFailure(state, error) {
      state.errors.loadResults = error;
      state.loading.loadResults = false;
    },

    editCandidateCommentRequest(state, id) {
      const candidate = state.candidates.find((c) => c.id === id);
      if (candidate) {
        candidate.errors.editComment = [];
        candidate.loading.editComment = true;
      }
    },
    editCandidateCommentSuccess(state, newCandidate) {
      let candidate = state.candidates.find((c) => c.id === newCandidate.id);
      if (candidate) {
        candidate.loading.editComment = false;
        candidate = Object.assign(candidate, newCandidate);
      }
    },
    editCandidateCommentFailure(state, { id, error }) {
      const candidate = state.candidates.find((c) => c.id === id);
      if (candidate) {
        candidate.errors.editComment = error;
        candidate.loading.editComment = false;
      }
    },

    setCandidateTestingStatus(state, { id, data }) {
      let candidate = state.candidates.find((c) => c.id === id);
      if (candidate) {
        candidate = Object.assign(candidate, data);
      }
    },
  },
  getters: {
    loading: (state) => state.loading,
    errors: (state) => state.errors,
    count: (state) => state.count,
    filter: (state) => state.filter,
    candidates: (state) => state.candidates,
    selected: (state) => state.selected,
    editedCandidate: (state) => state.editedCandidate,
    dialogCreateShow: (state) => state.dialogCreateShow,
    dialogDeleteShow: (state) => state.dialogDeleteShow,
  },
};
