import Vue from 'vue';
import Vuex from 'vuex';

import auth from './auth';
import notifications from './notifications';
import users from './users';
import profile from './profile';
import testing from './testing';
import positions from './positions';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    notifications,
    users,
    profile,
    testing,
    positions,
  },
});
